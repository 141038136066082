import { $ajaxService } from '@/service/request'
export const getGoodsList = async ({ page, size, query }) => {
  const { data } = await $ajaxService.post($ajaxService.baseUrl + 'good_list', {
    page,
    page_size: size,
    ...query
  })
  return data
}
export const getOrderList = async ({ page, size, query }) => {
  const { data } = await $ajaxService.post(
    $ajaxService.baseUrl + 'order_list',
    {
      page,
      page_size: size,
      ...query
    }
  )
  return data
}
