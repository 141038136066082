<template>
  <div class="share-goods-card">
    <!-- {{ info }} -->
    <div class="left-img-wrap">
      <van-image width="80" height="80" :src="info.mini_card_img" />
    </div>
    <div class="mid-content">
      <div class="title">{{ info.title || '-' }}</div>
      <div class="unit">
        规格：{{ info.spec || '-' }}/{{ info.unit || '-' }}
      </div>
      <div class="price1">原价：￥{{ info.original_price_yuan || '-' }}</div>
      <div class="price2">优惠价：￥{{ info.member_price_yuan || '-' }}</div>
    </div>
    <div class="right-content">
      <van-button size="small" plain color="#888" @click="shareGood(item)">
        分享
      </van-button>
      <div>销售数量：{{ info.sales_nums || 0 }}</div>
    </div>
  </div>
</template>

<script>
import { Image, Button } from 'vant'
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    shareGood() {
      this.$emit('share')
    }
  },
  components: {
    [Image.name]: Image,
    [Button.name]: Button
  },
  name: 'goodsCard'
}
</script>

<style lang="less" scoped>
@import '../../assets/style/theme.less';
.share-goods-card {
  display: flex;
  align-items: center;
  background: #fff;
  height: 96px;
  padding: 6px;
  box-sizing: border-box;
  font-size: 12px;
  margin-bottom: 6px;
  &:first-of-type {
    margin-top: 6px;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
  .left-img-wrap {
  }
  .mid-content {
    flex: 1;
    overflow: hidden;
    padding: 8px;
    flex-shrink: 1;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .price1 {
      font-size: 9px;
      text-decoration: line-through;
    }
    .price2 {
      color: @red;
    }
  }
  .right-content {
    flex-shrink: 1;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }
  .share-btn {
    min-width: 70px;
    margin-bottom: 20px;
  }
}
</style>
