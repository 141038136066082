import { ref, isRef } from 'vue'

export function pagination(load, { params, pageMode = false } = {}) {
  if (!isRef(params)) params = ref({})

  const list = ref([])
  const page = ref(0)
  const size = ref(10)
  const loading = ref(false)
  const finished = ref(false)
  const refreshing = ref(false)
  const error = ref(false)

  let _data = null

  const onReset = () => {
    list.value = []
    page.value = 0
    size.value = 10
  }

  const onLoad = async (currentPage) => {
    const data = Object.assign({}, params.value)

    if (pageMode) {
      Object.assign(data, {
        page: currentPage,
        size: size.value
      })
    } else {
      const offset = (currentPage - 1) * size.value
      Object.assign(data, {
        offset,
        limit: size.value
      })
    }

    _data = data

    try {
      const chunk = await load(data)
      // 兼容返回的新格式
      let dataArr = Array.isArray(chunk) ? chunk : chunk.data
      if (_data !== data) return false

      if (refreshing.value) {
        list.value = []
        finished.value = false
      }

      list.value.push(...dataArr)
      page.value = currentPage

      if (error.value) {
        error.value = false
      }
      // 兼容返回的新的格式
      if (chunk.count) {
        if (page.value * size.value > chunk.count) {
          finished.value = true
        }
      } else {
        if (dataArr.length < size.value) {
          finished.value = true
        }
      }
    } catch (e) {
      error.value = true
    }

    return true
  }

  const onRefresh = async () => {
    refreshing.value = true
    const complete = await onLoad(1)
    if (complete) refreshing.value = false
    return complete
  }

  const onNext = async () => {
    loading.value = true
    const complete = await onLoad(page.value + 1)
    if (complete) loading.value = false
    return complete
  }

  return {
    list,
    page,
    size,
    params,
    loading,
    finished,
    refreshing,
    error,
    onReset,
    onLoad,
    onRefresh,
    onNext
  }
}
